<template>
  <!--
    Used to create a collection
  -->
  <div class="collectionsAdd">
    <form>
      <div class="form-group">
        <h4 class="m-0 mt-3">
          {{ $t('collectionAddComponent.collection') }}
        </h4>
        <hr class="m-0 mb-3">
        <label>{{ $t('name') }}</label>
        <input
          v-model="collectionElement.name"
          v-focus
          :class="['form-control', { 'is-invalid': $validator.errors.has('Name') }]"
          type="text"
        >
        <hr class="m-0 mb-3">
        <label>{{ $t('description') }}</label>
        <input
          v-model="collectionElement.description"
          :class="['form-control', { 'is-invalid': $validator.errors.has('Description') }]"
          type="text"
        >

        <div class="mt-3">
          <label>{{ $t('type') }}</label>
          <div>
            <select
              v-model="collectionElement.type"
              :class="['form-control', { 'is-invalid': this.$validator.errors.has('Type') }]"
            >
              <option
                v-for="(key, index) in collectionTypes"
                :key="`collectionAdd_Option${ index }`"
                :value="key"
              >
                {{ key }}
              </option>
            </select>
            <span
              v-show="errors.has('Type')"
              class="badge badge-danger"
            >{{ errors.first('Type') }}</span>
          </div>
        </div>


        <div
          class="m-0 mt-3"
        >
          <label>{{ $t('staticResourceUploaderComponent.files') }}</label>
          <div class="input-group mb-3">
            <input
              ref="filesInput"
              type="file"
              accept="image/png"            
              multiple="multiple" 
              class="form-control cursor-pointer rounded"
              @change="getFilesFromInput($event)"
            >
          </div>
        </div>
        <div
          v-if="uploadPercentage > 0"
          class="input-group mb-3"
        >
          <div class="input-prepend mr-3">
            <label style="width:50px;">{{ $t('staticResourceUploaderComponent.progress') }}</label>
          </div>
          <div
            class="progress"
            style="width:100%"
          >
            <div
              :aria-valuenow="uploadPercentage"
              :style="`width:${ uploadPercentage }%;`"
              class="progress-bar"
              role="progressbar"
              aria-valuemin="0"
              aria-valuemax="100"
            >
              {{ uploadPercentage }} %
            </div>
          </div>
        </div>
        <span
          v-show="errors.has('Name')" 
          class="badge badge-danger"
        >{{ errors.first('Name') }}</span>
      </div>
      <button 
        class="btn btn-primary float-right" 
        :disabled="!files && !collectionElement.name || uploadingRightNow"
        @click.prevent="addAndUploadCollection()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="check"
        />{{ $t('add') }}
      </button>
      <div class="clearfix" />
    </form>
  </div>
</template>

<script>
import { errorMixin } from '@/mixins/errorMixin.js';

export default {
  name: "CollectionsAdd",
  mixins: [
    errorMixin
  ],
  data () {
    return {
      collectionElement: {},
      collectionTypes: ['Images', 'Flags'],
      files: null,
      uploadPercentage: null,
      uploadingRightNow: false
    }
  },
  created () {
    this.collectionElement.type = "Images";
  },
  methods: {
    async addFileCollection () {
       let postObject = {
            'name': this.collectionElement.name,
            'type': this.collectionElement.type,
            'description': this.collectionElement.description,
      }
      await this.axios.post(`/VideoPostProcessing/AddImageCollection`, postObject)
        .then((response) => {
          //this.$emit("reload");
          this.collectionElement.id = response.data; //TO BE TESTED
          this.error_clear();
        })
        .catch((error) => {
          this.error_clear();
          this.error_validate(error);
        });
    },
    async uploadFileCollection () {
      this.uploadingRightNow = true;
      this.uploadPercentage = 0;
      let bodyFormData = new FormData();
      for( var i = 0; i < this.files.length; i++ ){
        let file = this.files[i];

        bodyFormData.append('formFile', file);
      }
      await this.axios.post(`/VideoPostProcessingResource/UploadImageCollection/${this.collectionElement.id}`, 
        bodyFormData,
        { headers: { 'Content-Type': 'multipart/form-data' },
          onUploadProgress: function ( progressEvent ) {
            this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ));
          }.bind(this)
        }
      )
      .then((response) => {
        if (response && response.status && response.status == 200) {
          this.$emit("reload");
          this.$snotify.success(this.$t('staticResourceUploaderComponent.fileSavedMessage'));
        }
      })
      .finally(() => {
        this.uploadingRightNow = false;
        setTimeout(() => {
          this.uploadPercentage = 0;
        }, 2500);
      });
    },
    async addAndUploadCollection (){
      await this.addFileCollection();
      await this.uploadFileCollection();
    },
    getFilesFromInput () {
      this.files = this.$refs.filesInput.files; // Object.values(this.$refs.filesInput.files);
      this.uploadPercentage = 0;
    }
  }
}
</script>
<style scoped>
.collectionsAdd .progress {
  display: flex;
  flex: 1 1 auto;
  width: 1%;
  height: 20px;
  font-size: 1rem;
}
</style>
